import { combineReducers } from 'redux';
import { reducer as oidc } from 'redux-oidc';
import { apiStatusReducer, userReducer } from 'eleneo-react-commons';
import { reducer as notifications} from 'react-notification-system-redux';
// Inner components reducers
import { reducers as customers } from '../components/customers';
import { reducers as contracts } from '../components/contracts';
import { reducers as valorisations } from '../components/valorisations';

const app = combineReducers({
  oidc,
  notifications,
  apiStatus: apiStatusReducer,
  user: userReducer,
  customers,
  contracts,
  valorisations,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT_SUCCESS') {
    return app(undefined, action)
  }

  return app(state, action);
};

export default rootReducer;