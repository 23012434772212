import Notifications from 'react-notification-system-redux';

import * as api from '../api';

export const FETCH_CUSTOMER_CONTRACTS_REQUEST = 'FETCH_CUSTOMER_CONTRACTS_REQUEST';
export const FETCH_CUSTOMER_CONTRACTS_SUCCESS = 'FETCH_CUSTOMER_CONTRACTS_SUCCESS';
export const FETCH_CUSTOMER_CONTRACTS_FAILURE = 'FETCH_CUSTOMER_CONTRACTS_FAILURE';
export const INIT_CONTRATS = 'INIT_CONTRATS';
export const CHANGE_CONTRACT_SELECTION = 'CHANGE_CONTRACT_SELECTION';

export const RESET_CONTRACT_SELECTION = 'RESET_CONTRACT_SELECTION';
export const RESET_CONTRACTS = 'RESET_CONTRACTS';

export const VALORISER_CONTRACTS_REQUEST = 'VALORISER_CONTRACTS_REQUEST';
export const VALORISER_CONTRACTS_SUCCESS = 'VALORISER_CONTRACTS_SUCCESS';
export const VALORISER_CONTRACTS_FAILURE = 'VALORISER_CONTRACTS_FAILURE';

export const valoriser = (contractIds, platform = 'MUT') => async (dispatch) => {
  dispatch({
    type: VALORISER_CONTRACTS_REQUEST,
  });
  try {
    const rq = await api.newValorisation(contractIds, platform);
    let valorisations = null;
    if (rq.status === 200) {
      valorisations = await rq.json();
      dispatch({
        type: VALORISER_CONTRACTS_SUCCESS,
        valorisations,
      });
      dispatch({
        type: RESET_CONTRACT_SELECTION,
      });

      Notifications.success({
        title: `Les contrats vont être valorisés dans quelques instants`,
        position: 'bl',
        autoDismiss: 4,
      })
    }
  } catch (e) {
    console.error('Error loading customers', e);
    dispatch({
      type: VALORISER_CONTRACTS_FAILURE,
      message: e.Message || `Error requesting valorisation for ${contractIds} contracts ${platform}`,
    });

    Notifications.error({
      title: `Erreur lors de la demande de valorisation. Veuillez contacter votre administrateur si ce problème persiste`,
      position: 'bl',
      autoDismiss: 4,
    })

  }
};

export const fetchCustomerContrats = (customerId, platform = 'MUT') => async (dispatch) => {
  dispatch({
    type: FETCH_CUSTOMER_CONTRACTS_REQUEST,
  });
  try {
    const rq = await api.fetchCustomerContracts(customerId, platform);
    let cs = null;
    if (rq.status === 200) {
      cs = await rq.json();
      dispatch({
        type: FETCH_CUSTOMER_CONTRACTS_SUCCESS,
        cs,
      });
    }
  } catch (e) {
    console.error('Error loading customers', e);
    dispatch({
      type: FETCH_CUSTOMER_CONTRACTS_FAILURE,
      message: e.Message || `Error loading customer ${customerId} contracts ${platform}`,
    });
  }
};

export const changeSelection = (contractId) => async (dispatch) => {
  dispatch({
    type: CHANGE_CONTRACT_SELECTION,
    contractId
  });
};

export const resetSelection = () => async (dispatch) => {
  dispatch({
    type: RESET_CONTRACT_SELECTION
  });
};

export const resetContracts = () => async (dispatch) => {
  dispatch({
    type: RESET_CONTRACTS
  });
};