import { combineReducers } from 'redux';

import {
  FETCH_CUSTOMER_CONTRACTS_SUCCESS, FETCH_CUSTOMER_CONTRACTS_REQUEST,
  FETCH_CUSTOMER_CONTRACTS_FAILURE, RESET_CONTRACT_SELECTION, CHANGE_CONTRACT_SELECTION,
  RESET_CONTRACTS
} from '../actions';

// Contracts list
const list = (state = [], action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_CONTRACTS_SUCCESS:
      return action.cs;
    case FETCH_CUSTOMER_CONTRACTS_REQUEST:
      return [];
    case FETCH_CUSTOMER_CONTRACTS_FAILURE:
    case RESET_CONTRACTS:
      return [];
    default:
      return state;
  }
};

// Contract selection
const selection = (state = [], action) => {
  switch (action.type) {
    case CHANGE_CONTRACT_SELECTION:
      let newState;
      if(state.includes(action.contractId))
        newState = state.filter(ctrId => action.contractId !== ctrId);
      else
        newState = [...new Set(state.concat(action.contractId))];
      return newState;
    case FETCH_CUSTOMER_CONTRACTS_REQUEST:
    case RESET_CONTRACTS:
    case RESET_CONTRACT_SELECTION:
      return [];
    default:
      return state;
  }
};

export default combineReducers({
  list,
  selection,
});