import React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { translate } from 'react-i18next';

import mainLogo from '../../assets/images/logo_eleneo.svg';

import { ValorisationFinished, ValorisationPending, ValorisationRunning } from '../valorisations'

const Sidebar = ({ t }) => (
  <Row>
    <Col className="sidebar">
      <Row style={{ flex: 'none' }}>
        <Col className="px-0">
          <NavLink to="/" className="logo">
            <img src={mainLogo} alt={t('sidebar.logoAlt')} className="img-fluid" />
          </NavLink>
        </Col>
      </Row>
      <Row style={{ flexGrow: 1, overflowY: 'auto' }}>
        <Col>
          <Row>
            <Col>
              <ValorisationFinished />
            </Col>
          </Row>
          <Row>
            <Col>
              <ValorisationRunning />
            </Col>
          </Row>
          <Row>
            <Col>
              <ValorisationPending />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  </Row>
);

export default translate()(Sidebar);
