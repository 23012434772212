import { createStore, applyMiddleware, compose } from 'redux';
import { loadUser } from "redux-oidc";
import thunk from 'redux-thunk';
import throttle from 'lodash.throttle';
import rootReducer from './reducers';

import { loadState, saveState } from './helpers/localStorage';
import { userManager, accessTokenMiddleware } from 'eleneo-react-commons';

export default () => {
    const middleware = [
      thunk,
      accessTokenMiddleware,
    ];
    const persistedState = loadState();
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
        rootReducer,
        persistedState,
        composeEnhancers(applyMiddleware(...middleware))
    );

    loadUser(store, userManager);

    store.subscribe(throttle(() => {
         saveState({
          oidc: store.getState().oidc,
         });
    }, 1000));

    return store;
};
