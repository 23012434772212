import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Select from 'react-select'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withLoader } from 'eleneo-react-commons'

// INNER ACTIONS
import * as actions from '../actions'

// REDUCERS
import * as selectors from '../selectors'

class Customers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customer: null,
            platform: { value: 'MUT', label: 'MUT' },
        }
    }

    componentDidMount(evt) {
        this.props.fetchCustomers(this.state.platform);
    }

    selectPlatform = (value) => {
        this.setState({
            platform: value,
            customer: null,
        })
        this.props.changePlatform(value.value)
    }

    selectCustomer = (value) => {
        this.setState({
            customer: value,
        })

        this.props.changedCustomer(value.value, this.props.platform);
    }

    render() {
        return (
            <section style={{ display: 'flex', padding: '20px' }}>
                <div style={{ marginRight: '30px' }}>
                    <Select
                        className="Select-white select-inline select-square active"
                        options={[{ value: 'MUT', label: 'MUT' }, { value: 'CAR', label: 'CAR' }]}
                        value={this.state.platform}
                        onChange={this.selectPlatform}
                        openOnFocus
                        simpleValue
                        searchable={false}
                        clearable={false}
                    />
                </div>
                <div>
                    {
                        this.props.customers ?
                            <Select
                                className="Select-white select-inline select-square active"
                                options={this.props.customers.map(c => ({ value: c.Id, label: c.label }))}
                                value={this.state.customer}
                                onChange={this.selectCustomer}
                                openOnFocus
                                simpleValue
                                searchable={true}
                                clearable={true}>
                            </Select> : null
                    }
                </div>
            </section>);
    }
};

Customers.propTypes = {
    onPlatformChange: PropTypes.func.isRequired,
    onCustomerChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    isLoading: selectors.getCustomers(state) === null,
    customers: selectors.getCustomers(state),
    platform: selectors.getPlatform(state),
});

const mapDispatchToProps = (dispatch, { onPlatformChange, onCustomerChange}) => ({
    fetchCustomers(platform) {
        dispatch(actions.fetchCustomers(platform));
    },
    changePlatform(platform) {
        dispatch(actions.changePlatform(platform));
        dispatch(onPlatformChange(platform));
    },
    changedCustomer(uorId, platform) {
        dispatch(onCustomerChange(uorId, platform));
    },
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLoader
)(Customers);
