import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router'

class ValorisationItem extends Component {

  render() {
    const { NomCourt, NrjId } = this.props.valorisation && this.props.valorisation.Contrat;
    return (
      <li className='d-flex selected-contract'>
        {NrjId === 2 && <i className="icon-elec" />}
        {NrjId === 1 && <i className="icon-gaz" />}
        <span className="w-100">{this.props.valorisation.CustomerName} - {NomCourt}</span>
      </li>
    )
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = (dispatch, {contract}) => ({
});

ValorisationItem.propTypes = {
  valorisation: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ValorisationItem));
