import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose'
import { withLoader } from 'eleneo-react-commons'

// INNER ACTIONS
import * as actions from '../actions'

// REDUCERS
import * as selectors from '../selectors'

// COMPONENTS
import Valorisations from './Valorisations'

// SELECTORS
import { getPlatform } from '../../customers'

class ValorisationFinished extends Component {

    componentDidMount(props) {
        this.props.fetchValorisations(this.props.platform);
        this.interval = setInterval(this.props.fetchValorisations, 10000);
    }

    componentDidUpdate(oldProps) {
        if(oldProps.platform !== this.props.platform) {
            clearInterval(this.interval);
            this.props.fetchValorisations(this.props.platform);
            this.interval = setInterval(() => this.props.fetchValorisations(this.props.platform), 10000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return (<Valorisations valorisations={this.props.valorisations} title="valorisation.finished" />);
    }
};

const mapStateToProps = (state) => ({
    valorisations: selectors.getFinished(state),
    platform: getPlatform(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchValorisations(platform) {
      dispatch(actions.fetchFinished(platform));
    },
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLoader
)(ValorisationFinished);
