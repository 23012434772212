import { INIT_CONTRATS } from '../../contracts';
import * as api from '../api';

export const FETCH_CUSTOMERS_REQUEST = 'FETCH_CUSTOMERS_REQUEST';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILURE = 'FETCH_CUSTOMERS_FAILURE';

export const EVENT_CHANGE_PLATFORM = 'EVENT_CHANGE_PLATFORM'

export const fetchCustomers = (platform = 'MUT') => async (dispatch) => {
  dispatch({
    type: FETCH_CUSTOMERS_REQUEST,
  });
  try {
    const rq = await api.fetchCustomers(platform);
    let cs = null;
    if (rq.status === 200) {
      cs = await rq.json();
      dispatch({
        type: FETCH_CUSTOMERS_SUCCESS,
        cs,
      });
      dispatch({
        type: INIT_CONTRATS,
      });
    }
  } catch (e) {
    console.error('Error loading customers', e);
    dispatch({
      type: FETCH_CUSTOMERS_FAILURE,
      message: e.Message || 'Error loading customers',
    });
  }
}

export const changePlatform = (platform = 'MUT') => async (dispatch) => {
  dispatch({
    type: EVENT_CHANGE_PLATFORM,
    platform
  })
  
  dispatch(fetchCustomers(platform));
}