import { combineReducers } from 'redux';

import {
  FETCH_CUSTOMERS_SUCCESS, EVENT_CHANGE_PLATFORM, FETCH_CUSTOMERS_FAILURE,
} from '../actions';

// Customers
const list = (state = [], action) => {
  switch (action.type) {
    case FETCH_CUSTOMERS_SUCCESS:
      return action.cs;
    case FETCH_CUSTOMERS_FAILURE:
    case EVENT_CHANGE_PLATFORM:
      return []
    default:
      return state;
  }
};

// Platform
const platform = (state = 'MUT', action) => {
  switch (action.type) {
    case EVENT_CHANGE_PLATFORM:
      return action.platform;
    default:
      return state;
  }
};

export default combineReducers({
  list,
  platform,
});