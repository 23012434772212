import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, Nav, NavItem, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';

const TopNav = ({ setUserPanelOpen, userPanelOpen, t }) => (
    <Navbar color="primary" expand className="topNav">
        <Nav className="ml-auto" navbar>
            <NavItem>
                <Button color="link" title="" onClick={() => setUserPanelOpen(!userPanelOpen)}>
                    <i className="icon-user" />
                    <i className="icon-smallarrow-down" />
                </Button>
            </NavItem>
        </Nav>
    </Navbar>
);

TopNav.propTypes = {
    setUserPanelOpen: PropTypes.func.isRequired,
    userPanelOpen: PropTypes.bool.isRequired,
};

export default translate()(withRouter(TopNav));
