import Proptypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Input, Row, Col } from 'reactstrap';
import { Table, Thead, Tr, Th, Td } from 'reactable';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose'
import { withLoader } from 'eleneo-react-commons'

// SELECTORS
import * as selectors from '../selectors'
import { getPlatform } from '../../customers'

// ACTIONS
import * as actions from '../actions'

class Contracts extends Component {

  changeContractSelection (id, status) {
    this.props.changeContratSelection(id);
  }

  valoriser (ids) {
    if(ids && ids.length > 0)
      this.props.valoriser(ids, this.props.platform);
  }
  
  render() {
    const { contracts, t } = this.props;

    return (
      <div>
      { contracts && contracts.length > 0 ?
          <section>
          <Row>
            <Col>
              <section>
                <Button
                    className="rounded"
                    color="primary"
                    onClick={() => { this.valoriser(this.props.selectedContracts) }}>
                  {t('valorisation.valoriser')}
                </Button>
              </section>
            </Col>
          </Row>
          <Row>
            <Col>
              <section>
                <Table
                  className="table table-striped table-bordered"
                  sortable={[
                    'id',
                    'type',
                    'country',
                    'contract',
                    'startDate',
                    'endDate',
                  ]}>
                  <Thead>
                    <Th column="select"> </Th>
                    <Th column="type"> </Th>
                    <Th column="id">{t('contracts.board.id')}</Th>
                    <Th column="country">{t('contracts.board.country')}</Th>
                    <Th column="contract">{t('contracts.board.contract')}</Th>
                    <Th column="startDate">{t('contracts.board.startDate')}</Th>
                    <Th column="endDate">{t('contracts.board.endDate')}</Th>
                    <Th column="valo" className="text-center"><i className="icon-search" /></Th>
                  </Thead>
                  { contracts.map((i, index) => {
                      const checked = this.props.selectedContracts.includes(i.id);
                      return (<Tr key={index}>
                                <Td column="select" value={i.id}>
                                  <Input
                                    type="checkbox"
                                    value={i.id}
                                    checked={checked}
                                    onChange={e => this.changeContractSelection(i.id, !checked)}
                                  />
                                </Td>
                                <Td column="id" value={i.id}>{i.id}</Td>
                                <Td column="type" value={i.CtrNrjId}><i className={`${i.CtrNrjId === 2 ? 'icon-elec' : 'icon-gaz'} left-pad`}></i></Td>
                                <Td column="country">{i.CtrLibellePays}</Td>
                                <Td column="contract">{i.CtrNomCourt}</Td>
                                <Td column="startDate">{i.CtrDateDebut}</Td>
                                <Td column="endDate">{i.CtrDateFin}</Td>
                                <Td column="valo" className="text-center">
                                  <Button className="btn-table active" onClick={() => { this.valoriser([i.id]) }}>
                                    <i className="icon-bigarrow-right" />
                                  </Button>
                                </Td>
                              </Tr>);
                    })
                  }
                </Table>
              </section>
            </Col>
          </Row>
          </section>
         : null }
      </div>)
    }
}

const mapStateToProps = (state) => ({
  isLoading: selectors.getContracts(state) === null,
  contracts: selectors.getContracts(state),
  selectedContracts: selectors.getSelection(state),
  platform: getPlatform(state),
});

const mapDispatchToProps = (dispatch) => ({
  valoriser(ids, platform) {
    dispatch(actions.valoriser(ids, platform));
  },
  changeContratSelection(id) {
    dispatch(actions.changeSelection(id));
  },
  fetchCustomerContrats(customerId, platform) {
    dispatch(actions.fetchCustomerContrats(customerId, platform));
  },
});

Contracts.propTypes = {
  contracts: Proptypes.array.isRequired,
}

export default compose(
  withLoader,
  translate(),
  connect(mapStateToProps, mapDispatchToProps),
)(Contracts);
