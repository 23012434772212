import { apiConf as api } from 'eleneo-react-commons';

export const fetchPending = (platform) => {
  return api.superFetch({ url: `valorisations/pending`, platform });
};

export const fetchFinished = (platform) => {
  return api.superFetch({ url: `valorisations/finished`, platform });
};

export const fetchRunning = (platform) => {
  return api.superFetch({ url: `valorisations/running`, platform });
};
