import 'react-app-polyfill/stable';

import React from 'react'
import ReactDOM from 'react-dom'

import '../node_modules/eleneo-react-commons/build/css/index.css'
import './assets/css/index.sass'

import * as env from './helpers/env'
import { init } from 'eleneo-react-commons'

import App from './App'
import registerServiceWorker from './registerServiceWorker'
import configureStore from './configureStore'

init(env)

ReactDOM.render(
  <App store={configureStore()} />,
  document.getElementById('root')
);

registerServiceWorker();
