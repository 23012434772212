export const loadState = () => {
  try{
    const expires = localStorage.getItem('expires');
    const serializedState = localStorage.getItem('state');
    if(expires < new Date()){
      return undefined;
    }
    if(serializedState === null){
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch(err){
    return undefined;
  }
};
  
export const saveState = (state) => {
  try{
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch(err){
    console.error(err);
  }
};
  
export const resetState = () => {
  saveState({});
};