import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { compose, withState } from 'recompose';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'eleneo-react-commons';

import UserPanel from './UserPanel'
import Notifs from './Notifs'
import Sidebar from '../sidebar/Sidebar';
import TopNav from '../topnav/TopNav';
import Views from '../route/Views';

const Template = ({
  userPanelOpen,
  setUserPanelOpen
}) => (
  <>
    <Container fluid>
      <Row>
        <UserPanel userPanelOpen={userPanelOpen} setUserPanelOpen={setUserPanelOpen} />
        <Col className="sidebar-col" tag="aside">
          <Sidebar />
        </Col>
        <Col className="main-col">
          <Row>
            <TopNav userPanelOpen={userPanelOpen} setUserPanelOpen={setUserPanelOpen}/>
          </Row>
          <ErrorBoundary>
            <Views />
          </ErrorBoundary>
        </Col>
      </Row>
    </Container>
        <Notifs />
        </>
  );

Template.propTypes = {
  userPanelOpen: PropTypes.bool.isRequired,
  setUserPanelOpen: PropTypes.func.isRequired,
};

export default compose(
  withState('userPanelOpen', 'setUserPanelOpen', false),
)(Template);
