import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose'
import { withLoader } from 'eleneo-react-commons'

// COMPONENTS
import { Customers } from '../customers'
import { Contracts } from '../contracts'

// ACTIONS
import { actions as contractActions } from '../contracts'

class Home extends Component {

    render() {
        return (<div className="App">
            <div className="wrapper">
                <section>
                    <Customers onPlatformChange={contractActions.resetContracts} onCustomerChange={contractActions.fetchCustomerContrats} />
                </section>
                <section>
                    <Contracts />
                </section>
            </div>
        </div>
        );
    }
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLoader
)(Home);
