import * as api from '../api';

export const FETCH_VALO_RUNNING_REQUEST = 'FETCH_VALO_RUNNING_REQUEST';
export const FETCH_VALO_RUNNING_SUCCESS = 'FETCH_VALO_RUNNING_SUCCESS';
export const FETCH_VALO_RUNNING_FAILURE = 'FETCH_VALO_RUNNING_FAILURE';

export const FETCH_VALO_FINISHED_REQUEST = 'FETCH_VALO_FINISHED_REQUEST';
export const FETCH_VALO_FINISHED_SUCCESS = 'FETCH_VALO_FINISHED_SUCCESS';
export const FETCH_VALO_FINISHED_FAILURE = 'FETCH_VALO_FINISHED_FAILURE';

export const FETCH_VALO_PENDING_REQUEST = 'FETCH_VALO_PENDING_REQUEST';
export const FETCH_VALO_PENDING_SUCCESS = 'FETCH_VALO_PENDING_SUCCESS';
export const FETCH_VALO_PENDING_FAILURE = 'FETCH_VALO_PENDING_FAILURE';

export const fetchPending = (platform = 'MUT') => async (dispatch) => {
  dispatch({
    type: FETCH_VALO_PENDING_REQUEST,
  });

  try {
    const rq = await api.fetchPending(platform);

    let valorisations = null;
    if (rq.status === 200) {
      valorisations = await rq.json();
      dispatch({
        type: FETCH_VALO_PENDING_SUCCESS,
        valorisations,
      });
    }
  } catch (e) {
    console.error('Error loading pending valorisation', e);
    dispatch({
      type: FETCH_VALO_PENDING_FAILURE,
      message: e.Message || `Error loading penging valorisations`,
    });
  }
};


export const fetchRunning = (platform = 'MUT') => async (dispatch) => {
  dispatch({
    type: FETCH_VALO_RUNNING_REQUEST,
  });

  try {
    const rq = await api.fetchRunning(platform);

    let valorisations = null;
    if (rq.status === 200) {
      valorisations = await rq.json();
      dispatch({
        type: FETCH_VALO_RUNNING_SUCCESS,
        valorisations,
      });
    }
  } catch (e) {
    console.error('Error loading running valorisation', e);
    dispatch({
      type: FETCH_VALO_RUNNING_FAILURE,
      message: e.Message || `Error loading Running valorisations`,
    });
  }
};


export const fetchFinished = (platform = 'MUT') => async (dispatch) => {
  dispatch({
    type: FETCH_VALO_FINISHED_REQUEST,
  });

  try {
    const rq = await api.fetchFinished(platform);

    let valorisations = null;
    if (rq.status === 200) {
      valorisations = await rq.json();
      dispatch({
        type: FETCH_VALO_FINISHED_SUCCESS,
        valorisations,
      });
    }
  } catch (e) {
    console.error('Error loading finished valorisation', e);
    dispatch({
      type: FETCH_VALO_FINISHED_FAILURE,
      message: e.Message || `Error loading finished valorisations`,
    });
  }
};