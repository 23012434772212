import React from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';

import Home from '../home/Home';

const Views = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route path="/home/" component={Home} />
  </Switch>
);

export default withRouter(Views);