import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { translate } from 'react-i18next';

import ValorisationItem from './ValorisationItem';

const Valorisations = ({ valorisations, t, title, }) => (
  <div>
    <ul className="current-selection-sections">
          <span className="current-selection-label">{t(title)}</span>
          { valorisations && valorisations.map(valo => {
              return (
                <ul key={valo.Id} className="current-selection-item-list">
                  <ValorisationItem key={valo.Id} valorisation={valo} />
                </ul>
              )}
            )
          }
    </ul>
  </div>
);

Valorisations.propTypes = {
  valorisations: PropTypes.array,
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = dispatch => ({
});

export default compose(
  translate(),
  connect(mapStateToProps, mapDispatchToProps),
)(Valorisations);
