import { combineReducers } from 'redux';

import {
  FETCH_VALO_FINISHED_FAILURE, FETCH_VALO_FINISHED_REQUEST, FETCH_VALO_FINISHED_SUCCESS,
  FETCH_VALO_PENDING_FAILURE, FETCH_VALO_PENDING_REQUEST, FETCH_VALO_PENDING_SUCCESS,
  FETCH_VALO_RUNNING_FAILURE, FETCH_VALO_RUNNING_REQUEST, FETCH_VALO_RUNNING_SUCCESS,
 } from '../actions';

// Valo en attente
const pending = (state = [], action) => {
  switch (action.type) {
    case FETCH_VALO_PENDING_SUCCESS:
      return action.valorisations;
    case FETCH_VALO_PENDING_REQUEST:
      return null;
    case FETCH_VALO_PENDING_FAILURE:
      return [];
    default:
      return state;
  }
};

// Valo en cours
const running = (state = [], action) => {
  switch (action.type) {
    case FETCH_VALO_RUNNING_SUCCESS:
      return action.valorisations;
    case FETCH_VALO_RUNNING_REQUEST:
      return null;
    case FETCH_VALO_RUNNING_FAILURE:
      return [];
    default:
      return state;
  }
};

// Valo terminees
const finished = (state = [], action) => {
  switch (action.type) {
    case FETCH_VALO_FINISHED_SUCCESS:
      return action.valorisations;
    case FETCH_VALO_FINISHED_REQUEST:
      return null;
    case FETCH_VALO_FINISHED_FAILURE:
      return [];
    default:
      return state;
  }
};

export default combineReducers({
  pending,
  running,
  finished,
});
